.modal-top {
    margin-left: auto;
    margin-right: auto;
    width: 100%!important;
    background-color: #d3d3d3;
    height: 100%;
    max-height: 70px;
  }

  .logo-top {
    width: 100%;
    max-width: 60px;
  }