@import url(https://fonts.googleapis.com/css?family=Noto+Sans);
*{font-family:"Noto Sans",sans-serif}body{background-color:#fafafa}.blue-dark{color:#22274b}.gold{color:#dbb36e}.gray{color:#4d4d4d}.black{color:#000}.blue-light{color:#4267b2}
button{padding:2px}.is-selected{font-weight:bold;border:0}.LanguageSwitcher>.ml-5>button:after{content:"|"}.LanguageSwitcher>.ml-5>button:last-child:after{content:""}.LanguageSwitcher{text-transform:uppercase;text-align:right;background-color:#fff !important}.ml-5{margin-right:5%}.img-language{width:10%;max-width:.8rem}@-moz-document url-prefix(){select{direction:rtl}option{text-align:left}}
.img-logo{width:40%;margin-top:4rem}@media screen and (max-width: 400px){.img-hero{width:100%;max-height:250px}}
.modal-top{margin-left:auto;margin-right:auto;width:100% !important;background-color:#d3d3d3;height:100%;max-height:70px}.logo-top{width:100%;max-width:60px}
.text-box{margin-left:2px;margin-right:2px}.img-logo{width:40%;margin-top:5rem}@media screen and (min-width: 690px){.img-logo{margin-top:6.5rem}}.img-background{padding:0px !important;margin:0px !important;width:100%;z-index:0;position:relative;margin-bottom:1rem;height:200px;overflow:visible;background-image:url(/static/media/background_gradient.8f2129fa.png);background-position:center center;background-repeat:no-repeat;background-size:cover}.img-bottle{top:1rem;margin-top:-0.25rem;z-index:0;max-height:420px;width:auto}@media screen and (max-width: 600px){.margin-shift{margin-left:auto;margin-right:auto;margin-bottom:-70% !important}.img-bottle{top:1rem;max-height:250px;margin-top:1.5rem;z-index:0}.img-bottle-n{max-height:300px}}@media screen and (min-width: 600px){.img-background{height:300px}.img-bottle{width:40%}.sakenomy_text{font-size:1.5rem}}.wine-name{font-weight:bolder;letter-spacing:1px;font-size:.9rem}.master-brewery{background-color:#dbb376;padding:5px 5px;margin-left:auto;margin-right:auto;border-radius:999999rem;color:#fff}.brewery-address{color:#4d4d4d;opacity:.8;font-size:90%;margin-bottom:0px}.ic_pin{max-width:20px}.sales_blurbs{letter-spacing:1px;opacity:.7}.slogan-box{margin-top:10px;margin-bottom:10px}.cookie-box{justify-content:center}
.img-section{padding:0px !important;margin:0px !important;width:100%;z-index:0;position:relative;margin-bottom:1rem;max-height:200px;object-fit:cover}.title-text-box{width:100%;margin-top:-200px;z-index:0}.title_over{color:#fff}.title_over:before{content:"";display:block;margin:0 auto;padding-top:0px;width:6%;border-bottom:2px solid #fff}.px-0{padding-bottom:0px;padding-top:0px}.text-box{margin-left:2px;margin-right:2px}.detail-content-box{margin-top:24px;margin-right:24px;margin-bottom:24px;margin-left:24px}.detail-content-text{font-size:14px;line-height:1.5}.details-context-sub-title{font-weight:bold;margin-bottom:10px}.avatar{position:relative;width:70%;margin-bottom:-5px}.avatar-box{width:100%}.sake-text-box{position:relative;line-height:auto;text-align:center}@media screen and (min-width: 768px){.avatar{width:70%;margin:10px}}@media screen and (max-width: 400px){.img_section{max-height:200px}}@media screen and (max-width: 600px){.avatar-box{width:30%}.sake-text-box{width:70%;text-align:left}}.wrap-line-box{flex-wrap:wrap;flex-direction:row;display:flex}.sake-text-content{position:absolute;top:50%;height:30%;margin:0;transform:translateY(-50%)}
.border-top-bottom{border-top:1px solid #80808014;border-bottom:1px solid #80808014}.img-master{width:100%}.letterspacing{letter-spacing:1px}.master_brewer_title{background:linear-gradient(to right, #4267b2, #22274b);-webkit-background-clip:text;-webkit-text-fill-color:transparent;letter-spacing:1px}
hr{content:"";display:block;padding:1px 0px;margin:5% auto;width:10%;background:linear-gradient(to right, #4267b2, #22274b)}.sakenomi-icon{width:100%}.color-gold{color:#dbb36e}.expand-your{letter-spacing:1px;opacity:.8}.app-icon-box{margin-left:50%;transform:translateX(-50%)}.cookie-button-wrapper{width:50%;align-self:center}

.back-icon{width:100%}
.main-container{max-width:700px;margin-left:auto;margin-right:auto;padding:0px !important;background-color:#fff !important;box-shadow:0px 0px 60px 0px rgba(55,39,40,.3)}.mt16{margin-top:16px}.mb16{margin-bottom:16px}.main-part{align-items:center;padding-top:1rem}.item-box{width:100%;text-align:center}h1{display:block;font-size:1.7rem;-webkit-margin-before:.83em;margin-block-start:.83em;-webkit-margin-after:.83em;margin-block-end:.83em;-webkit-margin-start:0px;margin-inline-start:0px;-webkit-margin-end:0px;margin-inline-end:0px;font-weight:bold}h2{display:block;font-size:1.5em;-webkit-margin-before:.83em;margin-block-start:.83em;-webkit-margin-after:.83em;margin-block-end:.83em;-webkit-margin-start:0px;margin-inline-start:0px;-webkit-margin-end:0px;margin-inline-end:0px;font-weight:bold}h3{display:block;font-size:1rem;-webkit-margin-before:1em;margin-block-start:1em;-webkit-margin-after:1em;margin-block-end:1em;-webkit-margin-start:0px;margin-inline-start:0px;-webkit-margin-end:0px;margin-inline-end:0px;font-weight:bold}h4{display:block;-webkit-margin-before:1.33em;margin-block-start:1.33em;-webkit-margin-after:1.33em;margin-block-end:1.33em;-webkit-margin-start:0px;margin-inline-start:0px;-webkit-margin-end:0px;margin-inline-end:0px;font-weight:bold}.text-center{text-align:center}.text-left{text-align:left}.text-right{text-align:right}.px-0{padding-bottom:0px;padding-top:0px;padding-left:0px;padding-right:0px}.mb4{margin-bottom:24px}.mt4{margin-top:24px}.mx4{margin-left:24px;margin-right:24px}
