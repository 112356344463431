.border-top-bottom {
  border-top: 1px solid #80808014;
  border-bottom: 1px solid #80808014;
}

.img-master {
  width: 100%;
}

.letterspacing {
  letter-spacing: 1px;
}
.master_brewer_title {
  background: -webkit-linear-gradient(left, #4267b2, #22274b);
  background: -o-linear-gradient(right, #4267b2, #22274b);
  background: -moz-linear-gradient(right, #4267b2, #22274b);
  background: linear-gradient(to right, #4267b2, #22274b);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  
  letter-spacing: 1px;
}
