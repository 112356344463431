hr {
  content: "";
  display: block;
  padding: 1px 0px;
  margin: 5% auto;
  width: 10%;
  background: -webkit-linear-gradient(left, #4267b2, #22274b);
  background: -o-linear-gradient(right, #4267b2, #22274b);
  background: -moz-linear-gradient(right, #4267b2, #22274b);
  background: linear-gradient(to right, #4267b2, #22274b);
}

.sakenomi-icon {
    width: 100%;
}

.color-gold{
  color: #dbb36e;
}
.expand-your{
  letter-spacing: 1px; 
  opacity: 0.8;
}

.app-icon-box {
  margin-left: 50%;
  transform: translateX(-50%);
}

.cookie-button-wrapper {
  width: 50%;
  align-self: center;
}


  