button {
  padding: 2px;
}
.is-selected {
  font-weight: bold;
  border: 0;
}
.LanguageSwitcher > .ml-5 > button:after {
  content: "|";
}
.LanguageSwitcher > .ml-5 > button:last-child:after {
  content: "";
}
.LanguageSwitcher {
  text-transform: uppercase;
  text-align: right;
  background-color: #fff !important;
}
.ml-5 {
  margin-right: 5%;
}
.img-language {
  width: 10%;
  max-width: 0.8rem;
}
@-moz-document url-prefix() {
  select {
    direction: rtl;
  }
  option {
    text-align: left;
  }
}
