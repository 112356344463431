.text-box {
  margin-left: 2px;
  margin-right: 2px;
}

.img-logo {
  width: 40%;
  margin-top: 5rem;
}
@media screen and (min-width: 690px) {
  .img-logo {
    margin-top: 6.5rem;
  }
}

.img-background {
  padding: 0px !important;
  margin: 0px !important;
  width: 100%;
  z-index: 0;
  position: relative;
  margin-bottom: 1rem;
  height: 200px;
  overflow: visible;
  background-image: url("../../assets/background_gradient.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}


.img-bottle {
  top: 1rem;
  margin-top: -0.25rem;
  z-index: 0;
  max-height: 420px;
  width: auto;
}

@media screen and (max-width: 600px) {
  .margin-shift {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: -70% !important;
  }
  .img-bottle {
    top: 1rem;
    max-height: 250px;
    margin-top: 1.5rem;
    z-index: 0;
  }
  .img-bottle-n {
    max-height: 300px;
  }
}

@media screen and (min-width: 600px) {
  .img-background {
    height: 300px;
  }
  .img-bottle {
    width: 40%;
  }
  .sakenomy_text {
    font-size: 1.5rem;
  }
}

.wine-name {
  font-weight: bolder;
  letter-spacing: 1px;
  font-size: 0.9rem;
}

.master-brewery {
  background-color: #dbb376;
  padding: 5px 5px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 999999rem;
  color: white;
}

.brewery-address {
  color: #4d4d4d;
  opacity: 0.8;
  font-size: 90%;
  margin-bottom: 0px;
}

.ic_pin {
  max-width: 20px;
}

.sales_blurbs {
  letter-spacing: 1px;
  opacity: 0.7;
}

.slogan-box {
  margin-top: 10px;
  margin-bottom: 10px;
}

.cookie-box {
  justify-content: center;
}
