@import url('https://fonts.googleapis.com/css?family=Noto+Sans');

* {
  font-family: "Noto Sans", sans-serif;
}

body {
  background-color: #fafafa;
}
.blue-dark {
  color: #22274b;
}
.gold {
  color: #dbb36e;
}
.gray {
  color: #4d4d4d;
}
.black {
  color: #000000;
}
.blue-light {
  color: #4267b2;
}
