.img-logo {
    width: 40%;
    margin-top: 4rem;
  }
  
@media screen and (max-width: 400px) {
    .img-hero {
      width: 100%;
      max-height: 250px;
    }
  }