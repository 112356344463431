.img-section {
  padding: 0px !important;
  margin: 0px !important;
  width: 100%;
  z-index: 0;
  position: relative;
  margin-bottom: 1rem;
  max-height: 200px;
  object-fit: cover;
}

.title-text-box {
  width: 100%;
  margin-top: -200px;
  z-index: 0;
}
.title_over {
  color: white;
}
.title_over:before {
  content: ""; /* This is necessary for the pseudo element to work. */
  display: block; /* This will put the pseudo element on its own line. */
  margin: 0 auto; /* This will center the border. */
  padding-top: 0px;
  width: 6%; /* Change this to whatever width you want. */
  border-bottom: 2px solid rgb(255, 255, 255); /* This creates the border. Replace black with whatever color you want. */
}

.px-0 {
  padding-bottom: 0px;
  padding-top: 0px;
}

.text-box {
  margin-left: 2px;
  margin-right: 2px;
}

.detail-content-box {
  margin-top: 24px;
  margin-right: 24px;
  margin-bottom: 24px;
  margin-left: 24px;
}

.detail-content-text {
  font-size: 14px;
  line-height: 1.5;
}

.details-context-sub-title {
  font-weight: bold;
  margin-bottom: 10px;
}

.avatar {
  position: relative;
  width: 70%;
  margin-bottom: -5px; /*narrow space between section*/
}

.avatar-box {
  width: 100%;
}

.sake-text-box {
  position: relative;
  line-height: auto;
  text-align: center;
}

@media screen and (min-width: 768px) {
  .avatar {
    width: 70%;
    margin: 10px;
  }
}

@media screen and (max-width: 400px) {
  .img_section {
    max-height: 200px;
  }
}

@media screen and (max-width: 600px) {
  .avatar-box {
    width: 30%;
  }
  .sake-text-box {
    width: 70%;
    text-align: left;
  }
}

.wrap-line-box {
  flex-wrap: wrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  display: flex;
}

.sake-text-content {
  position: absolute;
  top: 50%;
  height: 30%;
  margin: 0;
  transform: translateY(-50%);
}
