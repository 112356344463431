.main-container {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  padding: 0px !important;
  background-color: #fff !important;
  box-shadow: 0px 0px 60px 0px rgba(55, 39, 40, 0.3);
  // height: 95vh;
}

.mt16 {
  margin-top: 16px;
}

.mb16 {
  margin-bottom: 16px;
}

.main-part {
  align-items: center;
  padding-top: 1rem;
}

.item-box {
  width: 100%;
  text-align: center;
}
h1 {
  display: block;
  font-size: 1.7rem;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

h2 {
  display: block;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

h3 {
  display: block;
  font-size: 1rem;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

h4 {
  display: block;
  margin-block-start: 1.33em;
  margin-block-end: 1.33em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.px-0 {
  padding-bottom: 0px;
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
}

.mb4 {
  margin-bottom: 24px;
}

.mt4 {
  margin-top: 24px;
}

.mx4 {
  margin-left: 24px;
  margin-right: 24px;
}
